import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import AfterTreatmentGif from '../../../content/images/root canal treatment.gif'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const AfterTreatmentPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section1_background = data.section1_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/instructions/after-treatment/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>After Treatment Instructions Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="After Treatment Instructions Indianapolis IN"/>
        <meta name="description" content="Indianapolis IN Endodontist Dr. Broady offers instruction for after endodontic treatment. 317-846-4980."/>
        <meta name="og:description" content="Indianapolis IN Endodontist Dr. Broady offers instruction for after endodontic treatment. 317-846-4980."/>
        <meta property="og:url" content="https://meridianendoindy.com/instructions/after-treatment/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/instructions/after-treatment/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="After Treatment Instructions Indianapolis IN"/>
        <meta name="twitter:description" content="Indianapolis IN Endodontist Dr. Broady offers instruction for after endodontic treatment. 317-846-4980."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="after-treatment-hero-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
        <div id="after-treatment-hero-section">
          <Container>
            <div className="col-lg-6 hero-left">
              <h1 className="hero-heading primary-text">
                After Completion of Endodontic Treatment
              </h1>
              <p className="hero-text">
                <strong>Endodontic treatment has now been completed.</strong><br/>
                The root canal system has been permanently sealed. However, the outer surface is sealed with a temporary restoration.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg-2"></section>
      <BackgroundImage
        Tag="section"
        className="after-treatment-section1-bg"
        fluid={section1_background}
        alt="Endodontics pc"
      >
      <section id="after-treatment-section-1">
        <Container>
          <div className="col-lg-12">
            <h4>After Completion of Endodontic Treatment</h4>
            <p>After your treatment is complete at Meridian Endodontics, the healing begins. Our doctor has permanently sealed the root canal system. However, you aren't finished yet. You still need to see a restoration specialist to place a crown to protect the tooth. This essential procedure protects the tooth from fracture and decay. We'll send your dentist a complete report of your treatment to guide them. </p>
            <p>During the time between appointments, it's vital to be careful with your uncrowned tooth. It's more prone to fracture after the treatment, and a second procedure might not be available to save the tooth again. Until you have the crown, chew on the other side of your mouth. Continue to use good hygiene practices to prevent further decay. </p>
          </div>
          <div className="col-lg-12 after-treatment-container">
            <h4>Are There Any Potential Problems After Treatment?</h4>
              <div className="row">
                <div className="col-lg-9">
                  <p>
                    Yes, although we do our best to save the tooth, complications can arise.
                  </p>
                  <ul className="mb-4">
                    <li>
                      <strong>Lower teeth and nerve injury.</strong> A small percentage of patients with a lower posterior tooth root canal develop a nerve injury during the root canal procedure. The root in these teeth is close to the nerve responsible for feeling in the lip, chin, and gums. Although it's rare, the nerve sometimes becomes inflamed during the procedure. After the local anesthetic wears off, patients can experience a tingling, altered sensation, and in a small number of people, lack of feeling in the affected area. This complication is temporary and resolves on its own in a few days, weeks, or months. However, a few patients might have permanent damage to the nerve.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3">
                  <img src={AfterTreatmentGif} />
                  {/*<iframe className="section1-video" src="https://www.youtube.com/embed/a64onos9JXw"></iframe>
                  {/*<iframe className="section1-video" src="https://fast.wistia.net/embed/iframe/71um0j6vsh?"></iframe>
                  <p><a href="https://www.youtube.com/watch?v=a64onos9JXw" target="_blank">Click here for high-res version </a></p> */}
                </div>
              </div>
              <div className="after-treatment-list-2">
                <ul>
                  <li className="mb-3">
                    <strong>Upper teeth and sinus communication.</strong> Similar to how the lower teeth are near the nerve endings, the upper set is very close to the sinus cavity. Therefore, root canal surgery can lead to a communication error in the sinuses. However, the good thing is this complication can resolve on its own. If it occurs during your endodontics treatment, the office will advise you on proceeding until it's back to normal. For example, we'll ask you to avoid blowing your nose for two to three days after the procedure. Additionally, if you need to sneeze, do it with your mouth open into a tissue. Keeping your mouth closed will create sinus pressure. Patients experiencing problems following their treatment should contact the office.
                  </li>
                  <li>
                    <strong>Post-operative infections.</strong> A post-operative infection can sometimes occur following a root canal. If you develop signs of an infection, contact our office for follow-up care. First, you'll need to come in for an appointment with the doctor. Most patients are given a course of antibiotics for a week to clear up the infection. Sometimes, further care is necessary.
                  </li>
                </ul>
                <strong><p>For questions about your after-treatment care, please contact or Meridian Endodontics office at <a href="tel:3178464980">317-846-4980.</a></p></strong>
              </div>
          </div>
        </Container>
      </section>
      </BackgroundImage>
      <section id="after-treatment-section-2">
        <ContactForm />
      </section>
    </Layout>
  )
}

export default AfterTreatmentPage

export const query = graphql`
  query AfterTreatmentPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_background: file(name: { eq: "after-treatment-section1bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "after-treatment-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 520, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
